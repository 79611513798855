import {
  Abstract
} from './Abstract';

export class BWPhysicalDevice extends Abstract { 
  constructor(session) {
      super('BusinessWallet/PhysicalDevice', session);
      this.device = null;
      this.is_active = false;
      this.name = null;
  }
}