<template>
  <Catalog :uploadURL="uploadURL" @new="newEntity" v-model="entity" :validate="validate" name="Terminales Fisicas" :rules="rules" :headers="headers" :inputs="inputs" />
</template>

<script>
import Catalog from "../../../components/templates/BasicCatalog.vue";
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
import { BWPhysicalDevice } from '../../../models/custom/business_wallet/BWPhysicalDevice';

export default {
  data() {
    return {
      entity: new BWPhysicalDevice(),
      inputs: [
        new CatalogInput("Sucursal", "id_branch", "branch"),  
        new CatalogInput("Dispositivo", "device", "text"),
        new CatalogInput("Nombre", "name", "text"),
        new CatalogInput("Activo", "is_active", "switch")  
      ],
      rules: [
        new Rule({ name: "device" })
      ],
      headers: [
        new HeaderGrid("Dispositivo", "device"),
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Activo", "is_active", { type: "boolean" })
      ],
      validate: new ValidateForm()
    }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new BWPhysicalDevice(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new BWPhysicalDevice(this.session);
    this.uploadURL = this.$config.api_route + "BusinessWallet/PhysicalDevice/import";
  }
}
</script>

<style>

</style>